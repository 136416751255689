import React from "react";

import AppRoutes from "./routes/Routes";
import ScrollToTop from "./components/UI/scroll-to-top";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <AppRoutes />
    </>
  );
};

export default App;

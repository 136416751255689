import {
  Avatar,
  Button,
  Card,
  Input,
  message,
  Spin,
  Upload,
  Statistic,
} from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import ImgCrop from "antd-img-crop";
import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from "react-hook-form";

import {
  addCompanyDetails,
  uploadCardCoverPic,
  uploadCompanyLogo,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { editorApiKey } from "../../../utils/constants";
import EditSocialMediaModalBranch from "../ViewBranchList/EditSocialMediaModalBranch";
import "./ViewAddBranch.css";
import { formikValidationRules } from "../../../utils/validationRules";

const ViewAddBranch = () => {
  const navigate = useNavigate();
  const { companyIdCtx, setCompanyIdCtx } = useContext(CompanyContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [fileList, setFileList] = useState([]);
  const [coverPicture, setCoverPicture] = useState("");
  const [coverFileList, setCoverFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSocialMediaModalVisible, setIsSocialMediaModalVisible] =
    useState(false);
  const [productServices, setProductServices] = useState("");

  const onFinishSubmit = async (values) => {
    setIsUpdatingCompany(true);
    try {
      let uploadedLogoPath =
        fileList.length > 0
          ? await handleCompanyLogoUpdate(fileList[0].originFileObj)
          : "";
      let uploadedCoverPath =
        coverFileList.length > 0
          ? await handleCoverPictureUpdate(coverFileList[0].originFileObj)
          : "";

      const addedValues = {
        ...values,
        company_logo: uploadedLogoPath,
        cover_pic: uploadedCoverPath,
        product_service: productServices,
      };

      const response = await addCompanyDetails(addedValues);
      if (response.status === 200) {
        message.success(response.data.message);
        reset();
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Update Company. Please try again later.");
    } finally {
      setIsUpdatingCompany(false);
    }
  };

  const handleCompanyLogoUpdate = async (imageFile) => {
    const response = await uploadCompanyLogo(imageFile);
    return response.status === 201
      ? response.data.data
      : message.error(response.data.message);
  };

  const handleCoverPictureUpdate = async (imageFile) => {
    const response = await uploadCardCoverPic(imageFile);
    return response.status === 201
      ? response.data.data
      : message.error(response.data.message);
  };

  const formatter = (value) => <CountUp end={value} separator="," />;

  const onChange = ({ fileList: newFileList }) => {
    setCompanyLogo(
      newFileList.length > 0
        ? URL.createObjectURL(newFileList[0].originFileObj)
        : ""
    );
    setFileList(newFileList);
  };

  const onCoverChange = ({ fileList: newFileList }) => {
    setCoverPicture(
      newFileList.length > 0
        ? URL.createObjectURL(newFileList[0].originFileObj)
        : ""
    );
    setCoverFileList(newFileList);
  };

  return (
    <>
      <Spin spinning={isUpdatingCompany} size="large">
        <Card
          type="inner"
          title={<span className="fw-bold text-center">Add Branch</span>}
          className="view-company-details-custom-card"
        >
          <div className="viewCompanyDetailsContainer">
            <form onSubmit={handleSubmit(onFinishSubmit)}>
              <Row className="companyDetails_Row1">
                <Col lg={4} md={6} sm={6} className="my-3">
                  <div className="centercol">
                    <label className="fw-bold my-1 w-100">Company Logo</label>
                    <Upload
                      accept="image/*"
                      onChange={onChange}
                      showUploadList={false}
                      fileList={fileList}
                      maxCount={1}
                      customRequest={({ file, onSuccess }) => onSuccess("ok")}
                    >
                      <Avatar
                        size={120}
                        src={companyLogo}
                        alt="Company Logo"
                        shape="square"
                      />
                    </Upload>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6} className="my-3">
                  <label className="fw-bold my-1">Cover Picture</label>
                  <br />
                  <ImgCrop rotationSlider aspectSlider showReset aspect={4 / 3}>
                    <Upload
                      accept="image/*"
                      onChange={onCoverChange}
                      showUploadList={false}
                      fileList={coverFileList}
                      maxCount={1}
                      customRequest={({ file, onSuccess }) => onSuccess("ok")}
                    >
                      <Avatar
                        size={120}
                        src={coverPicture}
                        alt="Cover Picture"
                        shape="square"
                      />
                    </Upload>
                  </ImgCrop>
                </Col>
                {/* <Col lg={4} md={0} sm={0} className="my-3">
                  <Row
                    className="my-1 p-4"
                    style={{ backgroundColor: "#12a0a2", borderRadius: "15px" }}
                  >
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Statistic
                        title={
                          <span className="fw-bold text-black">Used Cards</span>
                        }
                        value={0}
                        formatter={formatter}
                      />
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Statistic
                        title={
                          <span className="fw-bold text-black">
                            Remaining Cards
                          </span>
                        }
                        value={0}
                        formatter={formatter}
                      />
                    </Col>
                  </Row>
                </Col> */}
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="company_name"
                    control={control}
                    rules={formikValidationRules.company_name}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Entity Name
                        </label>
                        <Input
                          size="large"
                          placeholder="Enter Company Name"
                          {...field}
                        />
                        {errors.company_name && (
                          <span className="ant-form-item-explain text-danger">
                            {errors.company_name.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="company_email"
                    control={control}
                    rules={formikValidationRules.email}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Company Email
                        </label>
                        <Input
                          size="large"
                          placeholder="Enter Company Email"
                          {...field}
                        />
                        {errors.company_email && (
                          <span className="ant-form-item-explain text-danger">
                            {errors.company_email.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Description
                        </label>
                        <Input.TextArea
                          size="large"
                          placeholder="Enter Description"
                          {...field}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="company_address"
                    control={control}
                    rules={formikValidationRules.company_address}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Office Address
                        </label>
                        <Input.TextArea
                          size="large"
                          placeholder="Enter Company Address"
                          {...field}
                        />
                        {errors.company_address && (
                          <span className="ant-form-item-explain text-danger">
                            {errors.company_address.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="company_contact_number"
                    control={control}
                    rules={formikValidationRules.company_contact_number}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Office Contact
                        </label>
                        <Input
                          size="large"
                          placeholder="Enter Office Contact"
                          {...field}
                        />
                        {errors.company_contact_number && (
                          <span className="ant-form-item-explain text-danger">
                            {errors.company_contact_number.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="company_website"
                    control={control}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Company Website
                        </label>
                        <Input
                          size="large"
                          placeholder="Enter Company Website"
                          {...field}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <div className="ant-form-item">
                        <label className="ant-form-item-label fw-bold">
                          Location
                        </label>
                        <Input
                          size="large"
                          placeholder="Enter Google Map Location Link"
                          {...field}
                        />
                        {errors.location && (
                          <span className="ant-form-item-explain text-danger">
                            {errors.location.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="my-3">
                  <label className="fw-bold my-1 w-100">
                    Product / Services
                  </label>
                  <Editor
                    apiKey={editorApiKey}
                    value={productServices}
                    init={{
                      height: 300,
                      menubar: false,
                      placeholder: "Enter Product & Services",
                      plugins:
                        "anchor autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily ltr",
                      toolbar:
                        "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                      images_default_resizing: "scale",
                      images_resizing: true,
                      file_picker_types: "image",
                      file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "image") {
                          var input = document.createElement("input");
                          input.setAttribute("type", "file");
                          input.setAttribute("accept", "image/*");

                          input.onchange = function () {
                            var file = this.files[0];
                            var reader = new FileReader();

                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: file.name,
                              });
                            };

                            reader.readAsDataURL(file);
                          };

                          input.click();
                        }
                      },
                      media_live_embeds: true,
                      media_embeds: true,
                      content_css: "tinymce-5",
                    }}
                    onEditorChange={(content) => setProductServices(content)}
                  />
                </Col>
              </Row>

              <Row className="text-center">
                <Col lg={12} className="my-3">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || isUpdatingCompany}
                  >
                    {isUpdatingCompany ? "Saving Changes..." : "Save Changes"}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </Card>
      </Spin>

      <EditSocialMediaModalBranch
        isModalVisible={isSocialMediaModalVisible}
        setIsModalVisible={setIsSocialMediaModalVisible}
      />
    </>
  );
};

export default ViewAddBranch;

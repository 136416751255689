import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaShareAlt } from "react-icons/fa";
import "./ViewDigitalCard.css";
import {
  fetchViewDigitalCardAll,
  getVCFCardforDigitalCard,
} from "../../services/apiServices";
import { useParams } from "react-router-dom";
import { Button, Skeleton, Spin, message } from "antd";
import { FaUserPlus } from "react-icons/fa6";

import {
  AppAnimatedMapIcon,
  AppCardBackgroundImage,
  AppDefaultUser,
  AppPageNotFound,
  WhatsAppCustomIcon,
} from "../../utils/constants";
import {
  CompanyInfoItem,
  SocialMediaButton,
  socialPlatforms,
} from "../../components/UI/view-digital-card";

function ViewDigitalCard() {
  const { companyName, cardReference } = useParams();

  const [isFetchingCard, setIsFetchingCard] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    async function fetchCard() {
      try {
        setIsFetchingCard(true);
        let queries = { companyName, cardReference };
        const response = await fetchViewDigitalCardAll(queries);
        if (response.status === 200) {
          setCardDetails(response.data.data);
          setImageUrl(response.data.data.profile_picture);
        } else if (response.status === 404) {
          setCardDetails({});
        } else {
        }
      } catch (error) {
        console.error("Error fetching card details:", error);
        // message.error("Something Went Wrong. Please try again");
      } finally {
        setIsFetchingCard(false);
      }
    }

    fetchCard();
  }, [companyName, cardReference]);

  const handleAddToContacts = async () => {
    try {
      // Run your API to fetch vCard data
      const response = await getVCFCardforDigitalCard(cardDetails.id);

      // Create a Blob with the vCard data
      const blob = new Blob([response.data], { type: "text/vcard" });

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "contact.vcf"); // Set the filename for the vCard file

      // Programmatically click the link to trigger download
      link.click();
    } catch (error) {
      console.error("Error generating vCard:", error);
      message.error(error);
    }
  };

  const handleShareButtonClick = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "View this digital business card and connect with me.",
          text: "View this digital business card and connect with me.",
          url: window.location.href,
        });
      } else {
        throw new Error("Web Share API not supported.");
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };

  const openSocialLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Container
        fluid
        className="viewDigitalBusinessCardContainer"
        style={{
          backgroundImage: `url(${AppCardBackgroundImage})`,
        }}
      >
        {/* <Spin
          spinning={isFetchingCard}
          size="large"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        > */}
        <Row>
          <Col
            lg={6}
            md={6}
            sm={0}
            xs={0}
            className="viewDigitalCard_CompanyDetails d-none d-md-block"
          >
            <div className="viewDigitalCard_CompanyBrief">
              {Object.keys(cardDetails).length === 0 && !isFetchingCard ? (
                <>
                  <Image
                    src={AppPageNotFound}
                    width={"100%"}
                    className="mx-2 my-5"
                  />
                </>
              ) : (
                <Row>
                  <Image
                    className="viewDigitalCard_CompanyLogo"
                    src={cardDetails?.company_logo}
                    height={150}
                    width={150}
                    rounded
                    alt="Company Logo"
                  />
                </Row>
              )}

              <div className="viewDigitalCard_Vision">
                <div className="viewDigitalCard_CompanyVision-1">
                  Tap, Connect, Cultivate
                </div>
                <div className="viewDigitalCard_CompanyVision-1">
                  Where Business Networking Blossoms{" "}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="">
            <div className="viewBusinessCardContainer">
              <div className="viewCardContainer">
                {Object.keys(cardDetails).length === 0 && !isFetchingCard ? (
                  <div className="viewCardNotFound">
                    <Image
                      src={AppPageNotFound}
                      width={"100%"}
                      className="mx-2 my-5"
                    />
                    <p className="text-center fw-bolder fs-5 mx-4 my-5">
                      Card Not Found with Requested Company And Card Reference
                    </p>
                  </div>
                ) : (
                  <Row>
                    <div
                      className="viewCardImagesSection"
                      style={{
                        backgroundImage: `url(${cardDetails?.cover_pic})`,
                      }}
                    >
                      <Image
                        className="viewCardImages_profileImage"
                        src={imageUrl || AppDefaultUser}
                        roundedCircle
                        alt="Profile"
                        height={150}
                        width={150}
                      />

                      <Image
                        className="viewCardImages_cardCompanyLogo"
                        src={cardDetails?.company_logo}
                        alt="Card Company Logo"
                        height={"auto"}
                        width={150}
                      />
                    </div>
                    <div className="viewCardDetails p-4">
                      <div className="viewCardProfileBioDetails">
                        <Row className="align-items-center">
                          {!isFetchingCard ? (
                            <>
                              <Col lg={12} md={8} sm={8}>
                                <div className="view_card_content_title">{`${cardDetails?.first_name} ${cardDetails?.last_name}`}</div>
                                <div className="view_card_content_sub_title">{`${cardDetails?.designation} at ${cardDetails?.company_name}`}</div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Skeleton active />
                            </>
                          )}
                        </Row>
                      </div>
                      {/* <div className="viewCardCompanyLogo mb-2">
                          <Image
                            src={cardDetails?.company_logo}
                            rounded
                            height={"auto"}
                            width={"auto"}
                            style={{ maxHeight: "50px", maxWidth: "50px" }}
                          />
                        </div> */}
                      <div className="viewCardCompanyBioDetails">
                        <Row className="align-items-center">
                          {!isFetchingCard ? (
                            <>
                              <Col lg={8} md={8} sm={8} xs={8}>
                                <label className="fw-bold text-black">
                                  Our Address
                                </label>
                                <div className="view_card_content_sub_title">
                                  {cardDetails?.company_address}
                                </div>
                              </Col>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                <a
                                  href={cardDetails?.location}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Image
                                    src={AppAnimatedMapIcon}
                                    height={40}
                                    width={40}
                                    className="float-end"
                                  />
                                </a>{" "}
                              </Col>
                            </>
                          ) : (
                            <>
                              <Skeleton active />
                            </>
                          )}
                        </Row>
                      </div>

                      {!isFetchingCard ? (
                        <>
                          {cardDetails && cardDetails.bio && (
                            <div className="viewCardAboutMe">
                              <Row className="my-3">
                                {/* <label className="fw-bold text-black">
                                  Introduction
                                </label> */}
                                <div
                                  className="html_text_render"
                                  dangerouslySetInnerHTML={{
                                    __html: cardDetails?.bio,
                                  }}
                                />
                              </Row>
                            </div>
                          )}
                          {cardDetails && cardDetails.product_service && (
                            <div className="viewCardProductAndServices">
                              <Row className="my-3">
                                <div
                                  className="html_text_render"
                                  dangerouslySetInnerHTML={{
                                    __html: cardDetails?.product_service,
                                  }}
                                />
                              </Row>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <Skeleton active />
                          <Skeleton active />
                        </>
                      )}

                      <div className="viewCardCompanyInfo">
                        <Row className="mt-1 align-items-center">
                          {!isFetchingCard ? (
                            <>
                              <CompanyInfoItem
                                label="Email"
                                value={cardDetails?.user_email}
                                link={`mailto:${cardDetails?.user_email}`}
                              />
                              <CompanyInfoItem
                                label="Contact No."
                                value={cardDetails?.contact_number}
                                link={`tel:${cardDetails?.contact_number}`}
                              />
                              <CompanyInfoItem
                                label="Office Email"
                                value={cardDetails?.company_email}
                                link={`mailto:${cardDetails?.company_email}`}
                              />
                              <CompanyInfoItem
                                label="Corporate Website"
                                value={cardDetails?.company_website}
                                link={cardDetails?.company_website}
                              />
                              <CompanyInfoItem
                                label="Office Phone"
                                value={cardDetails?.company_contact_number}
                                link={`tel:${cardDetails?.company_contact_number}`}
                              />
                              {cardDetails?.personal_whatsapp && (
                                <CompanyInfoItem
                                  label="Personal Whatsapp"
                                  value={cardDetails?.personal_whatsapp}
                                  link={`https://wa.me/${cardDetails?.personal_whatsapp}`}
                                  icon={
                                    cardDetails?.personal_whatsapp
                                      ? WhatsAppCustomIcon
                                      : null
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Skeleton active />
                            </>
                          )}
                        </Row>
                      </div>

                      <div className="viewCardSocialLinks">
                        <Row>
                          {!isFetchingCard ? (
                            <>
                              {socialPlatforms.map(
                                (platform) =>
                                  cardDetails[platform] && (
                                    <SocialMediaButton
                                      key={platform}
                                      platform={platform}
                                      link={cardDetails[platform]}
                                      onClick={openSocialLink}
                                    />
                                  )
                              )}
                            </>
                          ) : (
                            <>
                              <Skeleton active />
                            </>
                          )}
                        </Row>
                      </div>

                      <div className="viewCardShareLinks">
                        <Row className="my-3">
                          <Col lg={4} md={4} sm={4} xs={3}>
                            <Button
                              className="w-100"
                              size="large"
                              icon={<FaShareAlt />}
                              onClick={handleShareButtonClick}
                            ></Button>
                          </Col>
                          <Col lg={8} md={8} sm={8} xs={9} className="ps-0">
                            <Button
                              className="w-100 bg-black text-white"
                              size="large"
                              onClick={handleAddToContacts} // Call the function on button click
                            >
                              <FaUserPlus className="fs-5 me-2" /> Save To
                              Phonebook
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div className="viewCardQRCode">
                        <center>
                          {!isFetchingCard ? (
                            <>
                              {cardDetails.qr_url && (
                                <Image
                                  src={cardDetails.qr_url}
                                  alt="QR Code"
                                  style={{
                                    width: "250px",
                                    height: "250px",
                                    maxWidth: "250px",
                                    maxHeight: "250px",
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Skeleton active />
                            </>
                          )}
                        </center>
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} sm={0} xs={0}></Col>
        </Row>
        {/* </Spin> */}
        <footer className="viewDigitalCard_Footer">
          <p className="text-center mt-2">
            {" "}
            © 2024 Powered by midin.app. All Rights Reserved.
          </p>
        </footer>
      </Container>
    </>
  );
}

export default ViewDigitalCard;

import { Image } from "react-bootstrap";
import { CiInstagram } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import {
  app_facebook_url,
  app_instagram_url,
  AppLogoLight,
} from "../../utils/constants";

export const MidinSocialIcons = () => (
  <div className="position-absolute bottom-0 start-50 translate-middle-x mb-5">
    <a href={app_facebook_url} target="_blank" rel="noopener noreferrer">
      <FaFacebookSquare className="fs-1 fw-bold text-white me-2" />
    </a>
    <a href={app_instagram_url} target="_blank" rel="noopener noreferrer">
      <CiInstagram className="fs-1 fw-bold text-white me-2" />
    </a>
  </div>
);

export const MidinLogoImage = () => (
  <NavLink to={"/"}>
    <Image src={AppLogoLight} alt="Logo" className="logoimg" />
  </NavLink>
);

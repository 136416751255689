import { Col, Image, Row } from "react-bootstrap";
import {
  FacebookCustomIcon,
  InstagramCustomIcon,
  LineCustomIcon,
  LinkedInCustomIcon,
  TelegramCustomIcon,
  TikTokCustomIcon,
  TwitterCustomIcon,
  WebsiteCustomIcon,
  WeChatCustomIcon,
  WeiboCustomIcon,
  WhatsAppCustomIcon,
  XHSCustomIcon,
  YouTubeCustomIcon,
} from "../../utils/constants";
import { Button } from "antd";

export const socialPlatforms = [
  "official_website",
  "facebook",
  "instagram",
  "linkedin",
  "whatsapp",
  "telegram",
  "we_chat",
  "line",
  "youtube",
  "twitter",
  "tiktok",
  "xiao_hong_shu",
  "weibo",
];

const platformDetails = {
  official_website: { label: "Website", icon: WebsiteCustomIcon },
  facebook: { label: "Facebook", icon: FacebookCustomIcon },
  instagram: { label: "Instagram", icon: InstagramCustomIcon },
  linkedin: { label: "LinkedIn", icon: LinkedInCustomIcon },
  whatsapp: { label: "WhatsApp", icon: WhatsAppCustomIcon },
  telegram: { label: "Telegram", icon: TelegramCustomIcon },
  we_chat: { label: "WeChat", icon: WeChatCustomIcon },
  line: { label: "Line", icon: LineCustomIcon },
  youtube: { label: "YouTube", icon: YouTubeCustomIcon },
  twitter: { label: "Twitter", icon: TwitterCustomIcon },
  tiktok: { label: "TikTok", icon: TikTokCustomIcon },
  xiao_hong_shu: { label: "XiaoHongShu", icon: XHSCustomIcon },
  weibo: { label: "Weibo", icon: WeiboCustomIcon },
};

export const SocialMediaButton = ({ platform, link, onClick }) => {
  const { label, icon } = platformDetails[platform];

  return (
    <Col lg={6} md={6} sm={6} xs={6} className="my-2">
      <Button
        className="w-100 d-flex align-items-center"
        size="large"
        onClick={() => onClick(link)}
      >
        <Row className="m-0 p-0">
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="m-0 p-0 d-flex align-items-center"
          >
            <Image
              className="float-start"
              src={icon}
              alt={`${label} Icon`}
              height={"90%"}
              width={"90%"}
              // style={{
              //   maxHeight: "100%",
              //   maxWidth: "100%",
              // }}
            />
          </Col>
          <Col
            lg={9}
            md={9}
            sm={9}
            xs={9}
            className="m-0 p-0 d-flex align-items-center"
          >
            <span className="button-content float-start mx-2">{label}</span>
          </Col>
        </Row>
      </Button>
    </Col>
  );
};

const openSocialLink = (link) => {
  window.open(link, "_blank");
};

export const CompanyInfoItem = ({ label, value, link, icon }) => {
  return (
    <Col lg={6} md={6} sm={6} xs={6} className="mb-3">
      <label className="fw-bold">{label}</label>
      <div className="text-muted">
        {link ? (
          <a
            href={link}
            className="html_link company-info-wrap"
            target="_blank"
            rel="noreferrer"
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
                className="custom-icon me-1"
                style={{
                  maxHeight: "100%",
                  maxWidth: "10%",
                }}
              />
            )}
            {value || "NA"}
          </a>
        ) : (
          <>
            {icon && (
              <img
                src={icon}
                alt="icon"
                className="custom-icon me-1"
                style={{
                  maxHeight: "100%",
                  maxWidth: "10%",
                }}
              />
            )}
            {value || "NA"}
          </>
        )}
      </div>
    </Col>
  );
};

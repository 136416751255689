import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Input, Select, Tag, Upload, message } from "antd";
import { Col, Row } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ImgCrop from "antd-img-crop";
import { useNavigate } from "react-router-dom";

import "./ViewCreateCard.css";
import ViewBusinessCardPreviewModal from "./ViewBusinessCardPreviewModal";
import MidinFooter from "../../MidinFooter/MidinFooter";
import {
  downloadSampleExcel,
  editorApiKey,
  initialBusinessCardData,
} from "../../../utils/constants";
import {
  createMultipleBusinessCard,
  getCompanyDetailsLists,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { validationRules } from "../../../utils/validationRules";

const { Option } = Select;

function ViewCreateCard() {
  const navigate = useNavigate();

  const [businessCardData, setBusinessCardData] = useState(
    initialBusinessCardData
  );
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [bioHtml, setBioHtml] = useState("");
  const [branches, setBranches] = useState([]);

  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url || file.preview;

    if (!src && file.originFileObj) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setPreviewImageUrl(src);
  };

  const onFinish = (values) => {
    setBusinessCardData({
      ...businessCardData,
      ...values,
    });

    handlePreviewModalOpen();
  };

  const handleExcelUpload = async (file) => {
    const allowedExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      message.error("Only .xlsx and .xls files are allowed.");
      return;
    }
    try {
      let fileData = { file: file, fieldName: "file" };
      const response = await createMultipleBusinessCard(fileData);
      if (response.status === 201) {
        message.success(response.data.message);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      message.error("Error While Creating Cards from File.", error.message);
    }
  };

  const handlePreviewModalOpen = () => {
    setIsPreviewModalVisible(true);
  };

  const handlePreviewModalClose = () => {
    setIsPreviewModalVisible(false);
  };

  const handleEditorChange = (content, editor) => {
    setBioHtml(content);
  };

  const getBranches = useCallback(async () => {
    try {
      const response = await getCompanyDetailsLists();
      if (response.status === 200) {
        setBranches(response.data.data);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to fetch branches. Please try again later.");
    } finally {
    }
  }, [navigate]);

  useEffect(() => {
    getBranches();
  }, [navigate, getBranches]);

  const renderOptionLabel = (branch) => (
    <span>
      <div className="text-wrap">
        {branch.company_name} - {branch.company_address}
      </div>
    </span>
  );

  return (
    <>
      <Card
        type="inner"
        title={<span className="fw-bold text-center">Create Card</span>}
        className="view-profile-custom-card"
      >
        <Row>
          <Col lg={3} md={0} sm={0} xs={0}></Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <Upload
              accept=".xlsx, .xls"
              beforeUpload={handleExcelUpload}
              showUploadList={false}
            >
              <Button type="primary" className="mb-3">
                Create Multiple Cards
              </Button>
            </Upload>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <a href={downloadSampleExcel} target="_blank" rel="noreferrer">
              <Button type="primary" className="mb-3">
                Download Sample File
              </Button>
            </a>
          </Col>
          <Col lg={3} md={0} sm={0} xs={0}></Col>
        </Row>

        <div className="viewCreateCardContainer">
          <Form
            form={form}
            ref={formRef}
            className="p-2"
            name="createCardForm"
            onFinish={onFinish}
          >
            <Row>
              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">First Name</label>
                <Form.Item
                  name="first_name"
                  rules={validationRules.first_name}
                  hasFeedback
                >
                  <Input placeholder="Enter First Name" size="large" />
                </Form.Item>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">Last Name</label>
                <Form.Item
                  name="last_name"
                  rules={validationRules.last_name}
                  hasFeedback
                >
                  <Input placeholder="Enter Last Name" size="large" />
                </Form.Item>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">Email</label>
                <Form.Item
                  name="user_email"
                  rules={validationRules.email}
                  hasFeedback
                >
                  <Input placeholder="Enter Email Address" size="large" />
                </Form.Item>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">Designation</label>
                <Form.Item
                  name="designation"
                  rules={validationRules.designation}
                  hasFeedback
                >
                  <Input placeholder="Enter Designation" size="large" />
                </Form.Item>
              </Col>

              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">Contact Number</label>
                <Form.Item
                  name="contact_number"
                  rules={validationRules.contact_number}
                  hasFeedback
                >
                  <Input placeholder="Enter Contact Number" size="large" />
                </Form.Item>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <label className="fw-bold my-1">WhatsApp Number</label>
                <Form.Item
                  name="personal_whatsapp"
                  rules={validationRules.personal_whatsapp}
                  hasFeedback
                >
                  <Input placeholder="Enter WhatsApp Number" size="large" />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <label className="fw-bold my-1">Profile Picture</label>
                <ImgCrop rotationSlider showReset>
                  <Upload
                    hasControlInside
                    accept="image/*"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    maxCount={1}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                  >
                    {fileList.length < 1 && (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>Upload Profile</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <label className="fw-bold my-2">Select Office Address</label>
                <Form.Item
                  name="associated_company"
                  rules={validationRules.associated_company}
                  hasFeedback
                >
                  <Select
                    showSearch
                    style={{ width: "100%", height: "max-content" }}
                    placeholder="Select a Branch"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  >
                    {branches &&
                      branches.map((branch) => (
                        <Option
                          key={branch.id}
                          value={branch.id}
                          label={branch.company_name}
                        >
                          {renderOptionLabel(branch)}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Col lg={12} md={12} sm={12}>
              <label className="fw-bold my-1">Bio</label>
              <Form.Item className="quill-editor">
                <Editor
                  apiKey={editorApiKey}
                  init={{
                    placeholder: "Enter Bio Details",
                    plugins:
                      "anchor autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily",
                    toolbar:
                      "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                    images_default_resizing: "scale",
                    images_resizing: true,
                    file_picker_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");

                        // Trigger the file selection dialog when the input element changes
                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();

                          reader.onload = function (e) {
                            // Pass the selected file back to the editor
                            callback(e.target.result, {
                              alt: file.name, // You can customize this if needed
                            });
                          };

                          reader.readAsDataURL(file);
                        };

                        // Click the input element to open the file selection dialog
                        input.click();
                      }
                    },
                    media_live_embeds: true,
                    media_embeds: true,
                    content_css: "tinymce-5",
                  }}
                  onEditorChange={handleEditorChange}
                  value={bioHtml}
                />
              </Form.Item>
            </Col>

            <Row>
              <Col></Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="w-100"
                >
                  Preview Card
                </Button>
              </Col>
              <Col></Col>
            </Row>
          </Form>
        </div>
      </Card>

      <MidinFooter />

      <ViewBusinessCardPreviewModal
        isVisible={isPreviewModalVisible}
        onClose={handlePreviewModalClose}
        data={businessCardData}
        imageUrl={previewImageUrl}
        fileList={fileList}
        onSuccess={(cardDetails) => {
          formRef.current.resetFields();
          form.resetFields();
          setFileList([]);
          setBioHtml("");
        }}
        bioHtml={bioHtml}
      />
    </>
  );
}

export default ViewCreateCard;

export const validationRules = {
  password: [
    {
      required: true,
      message: "Please Enter Password !",
    },
  ],
  old_password: [
    {
      required: true,
      message: "Please Enter Old Password !",
    },
  ],
  new_password: [
    {
      required: true,
      message: "Please Enter New Password !",
    },
  ],
  confirm_password: [
    {
      required: true,
      message: "Please Confirm New Password !",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("new_password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error("New Password & Confirm Password Must Be Same")
        );
      },
    }),
  ],
  first_name: [
    {
      required: true,
      message: "Please Enter First Name !",
    },
  ],
  last_name: [
    {
      required: true,
      message: "Please Enter Last Name !",
    },
  ],
  email: [
    {
      required: true,
      message: "Please Enter Email !",
    },
    {
      type: "email",
      message: "Please Enter a Valid Email !",
    },
  ],
  contact_number: [
    {
      required: true,
      message: "Please Enter Contact Number !",
    },
    {
      pattern: /^\d+$/,
      message: "Please Enter a Valid Contact Number !",
    },
  ],
  mobile_number: [
    {
      required: true,
      message: "Please Enter Mobile Number !",
    },
    {
      pattern: /^\d+$/,
      message: "Please Enter a Valid Mobile Number !",
    },
  ],
  phone_number: [
    {
      required: true,
      message: "Please Enter Phone Number !",
    },
    {
      pattern: /^\d+$/,
      message: "Please Enter a Valid Phone Number !",
    },
  ],
  designation: [
    {
      required: true,
      message: "Please Enter Designation !",
    },
  ],
  personal_whatsapp: [
    {
      required: true,
      message: "Please Enter your WhatsApp Number !",
    },
    {
      pattern: /^\d+$/,
      message: "Please Enter a Valid WhatsApp Number !",
    },
  ],
  associated_company: [
    {
      required: true,
      message: "Please Select an Office Address !",
    },
  ],
  company_name: [
    {
      required: true,
      message: "Please Enter Entity Name !",
    },
  ],
  company_address: [
    {
      required: true,
      message: "Please Enter Office Address !",
    },
  ],
  contact_person_name: [
    {
      required: true,
      message: "Please Enter Contact Person Name !",
    },
  ],
  contact_person_email: [
    {
      required: true,
      message: "Please Enter Contact Person Email !",
    },
  ],
  company_website: [
    {
      required: true,
      message: "Please Enter Website !",
    },
  ],
  location: [
    { required: true, message: "Please Enter Location URL !" },
    {
      type: "url",
      message: "Please Enter a Valid Location URL!",
    },
  ],
};

export const formikValidationRules = {
  company_name: {
    required: "Please Enter Entity Name !",
  },
  email: {
    required: "Please Enter Email !",
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      message: "Please Enter Valid Email Address !",
    },
  },
  company_address: {
    required: "Please Enter Company Address !",
  },
  company_contact_number: {
    required: "Please Enter Office Contact !",
  },
};

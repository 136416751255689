import {
  CreditCardOutlined,
  PlusOutlined,
  ProfileOutlined,
  QrcodeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FaListOl } from "react-icons/fa6";
import { MdOutlineAddBusiness, MdOutlinePassword } from "react-icons/md";

export const dashboardItems = [
  { key: "1", icon: <ProfileOutlined />, label: "Company Details" },
  { key: "2", icon: <PlusOutlined />, label: "Create Card" },
  { key: "3", icon: <CreditCardOutlined />, label: "Cards" },
  { key: "4", icon: <QrcodeOutlined />, label: "Cards QRs" },
  { key: "5", icon: <UserOutlined />, label: "Profile" },
  { key: "6", icon: <MdOutlinePassword />, label: "Change Password" },
  { key: "7", icon: <MdOutlineAddBusiness />, label: "Add Branch" },
  { key: "8", icon: <FaListOl />, label: "Branch List" },
];

import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteCard } from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { useNavigate } from "react-router-dom";

const DeleteCard = ({ visible, onOk, onCancel, record }) => {
  const navigate = useNavigate();

  const [isDeletingCard, setIsDeletingCard] = useState(false);

  const handleConfirmDeletingCard = async () => {
    setIsDeletingCard(true);
    try {
      const response = await deleteCard(record.id);
      if (response.status === 200) {
        message.success(response.data.message);
        onOk(response.data.success);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error Deleting card:", error);
      message.error("Failed to Delete the Card. Please try again later.");
    } finally {
      setIsDeletingCard(false);
    }
  };

  return (
    <Modal
      centered
      title={
        <span className="fw-bold fs-5">
          <ExclamationCircleFilled className="mx-2 text-danger" />
          Delete Card <span>Warning</span>
        </span>
      }
      open={visible}
      onCancel={onCancel}
      footer={[
        <div className="text-center">
          <Button key="back" onClick={onCancel} size="large" className="me-2">
            Cancel
          </Button>

          <Button
            danger
            key="submit"
            type="primary"
            onClick={handleConfirmDeletingCard}
            loading={isDeletingCard}
            size="large"
            className="ms-2"
          >
            Delete
          </Button>
        </div>,
      ]}
    >
      <p className="text-center mt-4 ">
        All QR Codes are Unique. If you Delete this Card, Your Printed Card with
        the QR Code will no longer work. You can choose to EDIT the card instead
        if you wish to reuse the same printed card.
      </p>
      <p className="text-center">
        Do you wish to continue to delete{" "}
        <strong>{`${record?.first_name?.toUpperCase()} ${record?.last_name?.toUpperCase()}'s`}</strong>{" "}
        card?
      </p>
    </Modal>
  );
};

export default DeleteCard;

import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

import "./ForgetPasswordPage.css";
import { forgetPassword } from "../../../services/apiServices";
import { AppBackgroundImage, AppLogoLight } from "../../../utils/constants";
import {
  MidinLogoImage,
  MidinSocialIcons,
} from "../../../components/UI/auth-screens";
import { validationRules } from "../../../utils/validationRules";

function ForgetPasswordPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await forgetPassword(values);
      if (response && response.status === 200) {
        message.success(response.data.message);
        navigate("/");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to login. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="container-fluid d-flex flex-column"
        style={{ height: "100vh" }}
      >
        <div className="row flex-grow-1">
          <div className="col-md-6 col-sm-12 p-0 position-relative">
            <img src={AppBackgroundImage} alt="Background" className="bgImg" />
            <div className="position-absolute top-50 start-0 translate-middle-y text-white mb-5 p-4 ">
              <h2 className="textsize">
                <b>Tap, Connect, Cultivate</b>
                <br />
                Where Business Networking Blossoms
              </h2>
            </div>
            <MidinLogoImage />
            <MidinSocialIcons />
          </div>
          <div className="col-sm-12 col-md-6 bg-dark d-flex align-items-center justify-content-center">
            <div className="card border-0 bg-dark w-50">
              <NavLink to={"/"}>
                <Image
                  src={AppLogoLight}
                  className="mx-auto mt-4 mb-3"
                  alt="LogoImgSize"
                  style={{ width: "200px" }}
                />
              </NavLink>

              <Form name="basic" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  hasFeedback
                  rules={validationRules.email}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter Your Email"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    className="btn-forget-pass w-100"
                    loading={loading}
                  >
                    Forget Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPasswordPage;

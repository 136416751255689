import React, { useEffect, useState } from "react";
import { Button, Layout, Menu, Space, message } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ViewProfile from "../../../components/Dashboard/ViewProfile/ViewProfile";
import ViewCompanyDetails from "../../../components/Dashboard/ViewCompanyDetails/ViewCompanyDetails";
import ViewCards from "../../../components/Dashboard/ViewCards/ViewCards";
import ViewChangePassword from "../../../components/Dashboard/ViewChangePassword/ViewChangePassword";
import ViewCreateCard from "../../../components/Dashboard/ViewCreateCard/ViewCreateCard";
import ViewCardsQR from "../../../components/Dashboard/ViewCardsQR/ViewCardsQR";
import ViewAddBranch from "../../../components/Dashboard/ViewAddBranch/ViewAddBranch";
import ViewBranchList from "../../../components/Dashboard/ViewBranchList/ViewBranchList";

import { logoutUser } from "../../../services/apiServices";
import "./CompanyDashboardPage.css";
import { AppLogoDark, AppLogoLight } from "../../../utils/constants";
import { dashboardItems } from "../../../components/UI/definations";

const { Content, Sider } = Layout;

const CompanyDashboardPage = () => {
  const navigate = useNavigate();

  const [previousTab, setPreviousTab] = useState(null);
  const [selectedTab, setSelectedTab] = useState(previousTab || "1");
  const [collapsed, setCollapsed] = useState(false);

  const handleMenuClick = (e) => {
    setPreviousTab(selectedTab);
    setSelectedTab(e.key);
    if (window.innerWidth < 992) {
      setCollapsed(true);
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "1":
        return <ViewCompanyDetails />;
      case "2":
        return <ViewCreateCard />;
      case "3":
        return <ViewCards />;
      case "4":
        return <ViewCardsQR />;
      case "5":
        return <ViewProfile />;
      case "6":
        return <ViewChangePassword />;
      case "7":
        return <ViewAddBranch />;
      case "8":
        return <ViewBranchList />;
      default:
        return <div>No Content</div>;
    }
  };

  const handleLogout = async () => {
    let isLoggedOut = await logoutUser();

    if (isLoggedOut) {
      navigate("/");
    } else {
      message.error("Logged Out Successfully");
    }
  };

  useEffect(() => {
    const previousTab = localStorage.getItem("previousTab");
    if (previousTab) {
      setSelectedTab(previousTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("previousTab", selectedTab);
  }, [selectedTab]);

  return (
    <>
      <div className="MainBg">
        <Layout className="main-dashboard-layout">
          <Sider
            theme="dark"
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            style={{
              backgroundColor: "#272C3B",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              zIndex: 1,
            }}
            className="d-flex flex-column"
          >
            <Space className="app-logo-desktop-view p-2">
              <Image src={AppLogoLight} width={150} className="text-center" />
            </Space>
            <div>
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[selectedTab]}
                onClick={handleMenuClick}
                style={{ backgroundColor: "#272C3B" }}
                className="mb-2"
              >
                {dashboardItems.map((item) => (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    className="sider-dashboard-menu-item my-2 fw-semibold"
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
            <div className="mt-auto p-3">
              <Button
                danger
                type="primary"
                className="w-100"
                onClick={handleLogout}
                icon={<LogoutOutlined />}
              >
                Logout
              </Button>
            </div>
          </Sider>
          <Layout>
            <Space className="app-logo-mobile-view">
              <Image src={AppLogoDark} width={150} className="text-center" />
            </Space>
            <Content
              style={{
                backgroundColor: "#F5F4F8",
                minHeight: "100vh",
                overflowY: "auto",
                marginLeft: collapsed ? 0 : 200,
                transition: "margin-left 0.3s",
              }}
            >
              <div
                style={{
                  padding: 16,
                  borderRadius: "16px",
                }}
                className="dashboard-container"
              >
                {renderContent()}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default CompanyDashboardPage;

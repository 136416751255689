export const serverErrorMessage =
  "Server Error... Please try again later or Contact the administrator";

// api keys
export const googleMapApiKey = "AIzaSyAz9BzJwfRWIDQTG8JdcJwn1JYJx1V25jg";
// export const editorApiKey = "wm5bqxko1kasuhyx26o0ax3jabo3kr7nj4gzhlm2oenw0ipn";
export const editorApiKey = "msip3dg77cme1j68cepq7c535rh0i4lap4oxfyl08l0pfvvh";

// sample docs link
export const downloadSampleExcel = "https://midin.app/public/demo-file.xlsx";

// app social links
export const app_facebook_url = "https://facebook.com/midinapp";
export const app_instagram_url = "https://www.instagram.com/midinapp/";

// app assets link
export const assets_base_url = "https://midin.app/assets/";

// app images
export const AppBackgroundImage = `${assets_base_url}static/app_background_image_resize.jpg`;
export const AppCardBackgroundImage = `${assets_base_url}static/app_background_image_view_card_resize.jpg`;
export const AppLogoLight = `${assets_base_url}static/app_logo_light.png`;
export const AppLogoDark = `${assets_base_url}static/app_logo_dark.png`;
export const AppDefaultUser = `${assets_base_url}static/default_user.png`;
export const AppMapIcon = `${assets_base_url}static/map_icon.png`;
export const AppAnimatedMapIcon = `${assets_base_url}static/app_animated_map_icon.gif`;
export const AppStaticQR = `${assets_base_url}static/static/qr_img.png`;
export const AppPageNotFound = `${assets_base_url}static/app_page_not_found.png`;

// app social icons
export const WhatsAppCustomIcon = `${assets_base_url}social/whatsapp.png`;
export const InstagramCustomIcon = `${assets_base_url}social/instagram.png`;
export const TelegramCustomIcon = `${assets_base_url}social/telegram.png`;
export const LinkedInCustomIcon = `${assets_base_url}social/linkedin.png`;
export const WeChatCustomIcon = `${assets_base_url}social/wechat.png`;
export const LineCustomIcon = `${assets_base_url}social/line.png`;
export const YouTubeCustomIcon = `${assets_base_url}social/youtube.png`;
export const TwitterCustomIcon = `${assets_base_url}social/twitter.png`;
export const TikTokCustomIcon = `${assets_base_url}social/tiktok.png`;
export const FacebookCustomIcon = `${assets_base_url}social/facebook.png`;
export const XHSCustomIcon = `${assets_base_url}social/xhs.png`;
export const WeiboCustomIcon = `${assets_base_url}social/weibo.png`;
export const WebsiteCustomIcon = `${assets_base_url}social/website.png`;

// app form fields
export const initialBusinessCardData = {
  first_name: "",
  last_name: "",
  user_email: "",
  designation: "",
  bio: "",
  contact_number: "",
  personal_whatsapp: "",
  profile_picture: "",
  associated_company: "",
};

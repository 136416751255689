import React from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Image } from "react-bootstrap";

export const QRDetailsModal = ({ visible, onCancel, selectedUserData }) => (
  <Modal
    title={
      <span className="fw-bold">
        <ExclamationCircleFilled className="text-primary mx-2" /> QR Details
      </span>
    }
    open={visible}
    onCancel={onCancel}
    footer={[
      <div className="text-center">
        <Button key="close" onClick={onCancel} danger size="large">
          Close
        </Button>
      </div>,
    ]}
  >
    {selectedUserData && (
      <div className="my-4 text-center">
        <p className="fw-bold my-4">
          Name:{" "}
          <span>{`${selectedUserData.first_name} ${selectedUserData.last_name}`}</span>
        </p>
        <p className="fw-bold my-2">Card QR</p>
        <Image src={selectedUserData.qr_url} height={300} width={300} />
      </div>
    )}
  </Modal>
);

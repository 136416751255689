import React, { useCallback, useEffect, useState } from "react";
import { Card, Form, message, Spin, Table } from "antd";
import { useNavigate } from "react-router-dom";

import { fetchCardsUrlList } from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { viewCardsQRColumns } from "../../UI/table-columns";
import MidinFooter from "../../MidinFooter/MidinFooter";
import { QRDetailsModal } from "../../UI/dialog-modal";

function ViewCardsQR() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFetchingCardsUrl, setIsFetchingCardsUrl] = useState(false);
  const [CardsData, setCardsData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const fetchViewCardsData = useCallback(async () => {
    setIsFetchingCardsUrl(true);
    try {
      const response = await fetchCardsUrlList();
      if (response && response.status === 200) {
        setCardsData(response.data.data);
        form.setFieldsValue(response.data.data);
        navigate("/dashboard");
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Load Details. Please try again later.");
    } finally {
      setIsFetchingCardsUrl(false);
    }
  }, [navigate, form]);

  useEffect(() => {
    fetchViewCardsData();
  }, [navigate, fetchViewCardsData]);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleViewQR = (record) => {
    setSelectedUserData(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedUserData(null);
  };

  const components = {
    header: {
      cell: (props) => (
        <th {...props} style={{ backgroundColor: "-moz-initial" }} />
      ),
    },
  };

  return (
    <>
      <Card
        type="inner"
        title={<span className="fw-bold text-center">Cards</span>}
        className="view-cards-qr-custom-card"
      >
        <div className="viewCardsQRContainer table-responsive">
          <Spin spinning={isFetchingCardsUrl} size="large">
            <Table
              rowKey={(record) => record.id}
              bordered
              size="large"
              pagination={{
                position: ["bottomRight"],
                pageSize,
                pageSizeOptions: ["5", "10", "20", "50"],
                showSizeChanger: true,
                onShowSizeChange: handlePageSizeChange,
              }}
              columns={viewCardsQRColumns(handleViewQR)}
              dataSource={CardsData}
              components={components}
              scroll={{ x: true }}
            />
          </Spin>
        </div>
      </Card>

      <MidinFooter />

      <QRDetailsModal
        visible={modalVisible}
        onCancel={handleModalClose}
        selectedUserData={selectedUserData}
      />
    </>
  );
}

export default ViewCardsQR;

import React from "react";
import {
  Button,
  Space,
  Popconfirm,
  Tag,
  message,
  Tooltip,
  Dropdown,
  Menu,
  Avatar,
  Typography,
} from "antd";
import { FaCircleCheck, FaRegCircleXmark } from "react-icons/fa6";
import { EllipsisOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import { deleteCompanyDetails } from "../../services/apiServices";
import { handleAuthenticationError } from "../../utils/authHelpers";

const { Text } = Typography;

// Columns for Branch list Table
export const viewBranchListColumns = ({
  handleEdit,
  getBranches,
  navigate,
}) => [
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Address",
    dataIndex: "company_address",
    key: "company_address",
  },
  {
    title: "Default Address",
    key: "is_default_address",
    render: (text, record) => (
      <>
        {record.is_default_address && (
          <Tag icon={<FaCircleCheck />} color="success"></Tag>
        )}
        {!record.is_default_address && (
          <Tag icon={<FaRegCircleXmark />} color="error"></Tag>
        )}
      </>
    ),
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <Space size="middle">
        <Button type="primary" onClick={() => handleEdit(record)} size="small">
          Edit
        </Button>
        {record.is_main_company && (
          <Tag icon={<ExclamationCircleFilled />} color="processing">
            Primary Address
          </Tag>
        )}
        {!record.is_main_company && (
          <Popconfirm
            title="Are you sure to delete this company detail?"
            onConfirm={async () => {
              try {
                const response = await deleteCompanyDetails(record.id);
                if (response.status === 200) {
                  message.success("Company detail deleted successfully");
                  getBranches();
                } else if (response.status === 401) {
                  handleAuthenticationError(response.data.message, navigate);
                } else {
                  message.error(response.data.message);
                }
              } catch (error) {
                console.error("Failed to delete company detail:", error);
                message.error(
                  "Failed to Delete Company Detail. Please try again later."
                );
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="primary" size="small">
              Delete
            </Button>
          </Popconfirm>
        )}
      </Space>
    ),
  },
];

export const viewCardsColumns = ({
  handleDeactivateCardModalOpen,
  handleDeleteCardModalOpen,
  handleEditCardModalOpen,
}) => [
  {
    title: <div className="text-center fw-bold">Name</div>,
    dataIndex: "first_name",
    render: (text, record) => (
      <>
        <Avatar src={record.profile_picture} className="me-2" />
        <Text strong>{`${record.first_name} ${record.last_name}`}</Text>
      </>
    ),
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    width: "20%",
  },
  {
    title: <div className="text-center fw-bold">Email</div>,
    dataIndex: "user_email",
    width: "20%",
  },
  {
    title: <div className="text-center fw-bold">Designation</div>,
    dataIndex: "designation",
  },
  {
    title: <div className="text-center fw-bold">Contact Number</div>,
    dataIndex: "contact_number",
  },
  {
    title: <div className="text-center fw-bold">Card URL</div>,
    dataIndex: "card_url",
    render: (text) => (
      <Button
        href={text}
        type="primary"
        size="small"
        target="_blank"
        rel="noopener noreferrer"
        className="text-decoration-none"
      >
        View Card
      </Button>
    ),
  },
  {
    title: <div className="text-center fw-bold">Status</div>,
    dataIndex: "is_active_for_qr",
    render: (text, record) => (
      <Tooltip
        title={!record.is_deactivated ? "Activated" : "Deactivated"}
        color={record.is_deactivated ? "green" : "red"}
        trigger="click"
        autoAdjustOverflow={true}
      >
        {!record.is_deactivated ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        )}
      </Tooltip>
    ),
    width: "5%",
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="2"
              onClick={() => handleDeactivateCardModalOpen(record)}
            >
              {record.is_deactivated ? "Activate Card" : "Deactivate Card"}
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => handleDeleteCardModalOpen(record)}
            >
              Delete Card
            </Menu.Item>
            <Menu.Item key="4" onClick={() => handleEditCardModalOpen(record)}>
              Edit Card
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Button type="link" icon={<EllipsisOutlined />} />
      </Dropdown>
    ),
    width: "10%",
  },
];

export const viewCardsQRColumns = (handleViewQR) => [
  {
    title: <div className="text-center fw-bold">S.No</div>,
    render: (text, record, index) => index + 1,
    align: "center",
    width: "5%",
  },
  {
    title: <div className="text-center fw-bold">Name</div>,
    dataIndex: "first_name",
    render: (text, record) => (
      <>
        <Text
          strong
          style={{ cursor: "pointer" }}
        >{`${record.first_name} ${record.last_name}`}</Text>
      </>
    ),
    align: "center",
    width: "15%",
    ellipsis: true,
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
  },
  {
    title: <div className="text-center fw-bold">View QR</div>,
    dataIndex: "qr_url",
    render: (text, record) => (
      <center>
        <Button
          type="primary"
          size="small"
          onClick={() => handleViewQR(record)}
        >
          View QR
        </Button>
      </center>
    ),
    align: "center",
    width: "15%",
    ellipsis: true,
  },
  {
    title: <div className="text-center fw-bold">Card URL</div>,
    dataIndex: "card_url",
    render: (text, record) => (
      <center>
        <Button
          href={text}
          type="primary"
          size="small"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none"
        >
          View Card
        </Button>
      </center>
    ),
    align: "center",
    width: "15%",
    ellipsis: true,
  },
];

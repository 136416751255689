import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Upload, Avatar, message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { editorApiKey } from "../../../utils/constants";
import ImgCrop from "antd-img-crop";
import {
  editCompanyDetails,
  editExtraCompanyDetails,
  uploadCardCoverPic,
  uploadCompanyLogo,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { useNavigate } from "react-router-dom";
import { validationRules } from "../../../utils/validationRules";
import EditSocialMediaModalBranch from "./EditSocialMediaModalBranch";

const EditBranchModal = ({ isVisible, onCancel, onSuccess, initialValues }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [productServices, setProductServices] = useState(
    initialValues?.product_service
  );
  const [companyLogo, setCompanyLogo] = useState("");
  const [fileList, setFileList] = useState([]);
  const [coverPicture, setCoverPicture] = useState("");
  const [coverFileList, setCoverFileList] = useState([]);
  const [isSocialMediaModalVisible, setIsSocialMediaModalVisible] =
    useState(false);

  const handleEditorChange = (content) => {
    form.setFieldsValue({ product_service: content });
    setProductServices(content);
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleCompanyLogoUpdate = async (imageFile) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;

      const response = await uploadCompanyLogo(imageFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
        return;
      }
    } else {
      return initialValues.company_logo || "";
    }
  };

  const handleCoverPictureUpdate = async (imageFile) => {
    if (coverFileList.length > 0) {
      const response = await uploadCardCoverPic(imageFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
        return;
      }
    } else {
      return initialValues.cover_pic || "";
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const newAvatarPreview = URL.createObjectURL(
        newFileList[0].originFileObj
      );
      setCompanyLogo(newAvatarPreview);
    } else {
      setCompanyLogo("");
    }
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onCoverChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const newAvatarPreview = URL.createObjectURL(
        newFileList[0].originFileObj
      );
      setCoverPicture(newAvatarPreview);
    } else {
      setCoverPicture("");
    }
    setCoverFileList(newFileList);
  };

  const onCoverPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onFinishSubmit = async (values) => {
    try {
      let uploadedLogoPath = initialValues.company_logo;
      let uploadedCoverPath = initialValues.cover_pic;

      if (fileList && fileList.length > 0 && fileList[0]?.originFileObj) {
        uploadedLogoPath = await handleCompanyLogoUpdate(
          fileList[0]?.originFileObj
        );
      }

      if (
        coverFileList &&
        coverFileList.length > 0 &&
        coverFileList[0]?.originFileObj
      ) {
        uploadedCoverPath = await handleCoverPictureUpdate(
          coverFileList[0]?.originFileObj
        );
      }

      const updatedValues = {
        ...values,
        company_id: initialValues.id,
        company_logo: uploadedLogoPath || initialValues.company_logo,
        cover_pic: uploadedCoverPath || initialValues.cover_pic,
        product_service: productServices,
      };


      const response = await editExtraCompanyDetails(updatedValues);
      if (response && response.status === 200) {
        message.success(response.data.message);
        onSuccess(true);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Update Company. Please try again later.");
    } finally {
    }
  };

  const showSocialMediaModal = () => {
    setIsSocialMediaModalVisible(true);
  };

  const handleSocialMediaModalCancel = () => {
    setIsSocialMediaModalVisible(false);
  };

  const handleSocialMediaModalSave = (details) => {
    setIsSocialMediaModalVisible(false);
  };

  return (
    <Modal
      centered
      title=<div style={{ display: "flex", alignItems: "center" }}>
        <ExclamationCircleFilled className="text-primary fs-5 me-4" />
        <span>Edit Branch Details</span>
      </div>
      open={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width="90%"
    >
      <Form
        form={form}
        layout="vertical"
        name="editBranchForm"
        initialValues={initialValues}
        onFinish={onFinishSubmit}
      >
        <div className="edit-card-modal-form">
          <Row className="companyDetails_Row1">
            <Col lg={4} md={6} sm={6} className="my-3">
              <div className="centercol">
                <label className="fw-bold my-1 w-100">Company Logo</label>
                <div>
                  <Upload
                    accept="image/*"
                    onChange={onChange}
                    onPreview={onPreview}
                    showUploadList={false}
                    fileList={fileList}
                    maxCount={1}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                  >
                    <label htmlFor="avatar-upload">
                      <Avatar
                        id="avatar-upload"
                        size={120}
                        src={companyLogo || initialValues?.company_logo}
                        alt="Company Logo"
                        shape="square"
                      />
                    </label>
                  </Upload>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6} className="my-3">
              <label className="fw-bold my-1">Cover Picture</label>
              <br />
              <ImgCrop rotationSlider aspectSlider showReset aspect={4 / 3}>
                <Upload
                  accept="image/*"
                  onChange={onCoverChange}
                  onPreview={onCoverPreview}
                  showUploadList={false}
                  fileList={coverFileList}
                  maxCount={1}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                >
                  <label htmlFor="cover-avatar-upload">
                    <Avatar
                      id="cover-avatar-upload"
                      size={120}
                      src={coverPicture || initialValues?.cover_pic}
                      alt="Cover Picture"
                      shape="square"
                    />
                  </label>
                </Upload>
              </ImgCrop>
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Entity Name</label>
              <Form.Item
                name="company_name"
                rules={validationRules.company_name}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Office Email</label>
              <Form.Item name="company_email" rules={validationRules.email}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1">Description</label>
              <Form.Item name="description">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Office Address</label>
              <Form.Item
                name="company_address"
                rules={validationRules.company_address}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Office Contact</label>
              <Form.Item
                name="company_contact_number"
                rules={validationRules.contact_number}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Website</label>
              <Form.Item
                name="company_website"
                rules={validationRules.company_website}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <label className="fw-bold my-1 w-100">Update Location</label>
              <Form.Item name="location" rules={validationRules.location}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="my-1">
              <label className="fw-bold w-100">Edit Social Media</label>
              <Button type="primary" onClick={showSocialMediaModal}>
                Edit Social Media
              </Button>
            </Col>
          </Row>
          <Form.Item name="product_service">
            <label className="fw-bold my-1 w-100">Product & Services</label>
            <Editor
              apiKey={editorApiKey}
              initialValue={initialValues?.product_service}
              init={{
                placeholder: "Enter Company Product & Services Details",
                plugins:
                  "anchor media autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily",
                toolbar:
                  "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                images_default_resizing: "scale",
                images_resizing: true,
                file_picker_types: "image",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image" || meta.filetype === "video") {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute(
                      "accept",
                      meta.filetype === "image" ? "image/*" : "video/*"
                    );

                    input.onchange = function () {
                      var file = this.files[0];
                      var reader = new FileReader();

                      reader.onload = function (e) {
                        callback(e.target.result, {
                          alt: file.name,
                        });
                      };

                      reader.readAsDataURL(file);
                    };

                    input.click();
                  }
                },
                media_live_embeds: true,
                media_embeds: true,
                content_css: "tinymce-5",
              }}
              onEditorChange={handleEditorChange}
            />
          </Form.Item>
        </div>
        <Row className="edit-card-modal-footer mt-2">
          <Col lg={3} md={3} sm={3}></Col>
          <Col lg={3} md={3} sm={3}>
            <Button size="large" className="w-100" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col lg={3} md={3} sm={3}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-100"
            >
              Edit Branch
            </Button>
          </Col>
          <Col lg={3} md={3} sm={3}></Col>
        </Row>
      </Form>

      <EditSocialMediaModalBranch
        visible={isSocialMediaModalVisible}
        onCancel={handleSocialMediaModalCancel}
        onSave={handleSocialMediaModalSave}
        companyID={initialValues?.id}
        // companyIdCtx={companyIdCtx}
      />
    </Modal>
  );
};

export default EditBranchModal;
